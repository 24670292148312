import React, {FC} from 'react';
import {Link} from 'gatsby';
import Helmet from 'react-helmet';
import AutomationDetailsContent from '../automation-details-content';
import AutomationDetailsHeader, { automationWithoutDataInput } from '../automation-details-header';
import Layout from '../../layout/layout';
import {PageHero} from '../../page-hero';
import Section from '../../section';
import {IPageContextAutomatin} from '../../../types';
import WorkflowCard from '../../public-workflows/workflow-card';
import AutomationInputOutput from '../automation-input-output';
import Breadcrumb from '../../breadcrumb';
import {cleanURL} from '../../../helpers';
import Button from '../../button';
import {
  automationOutputs,
  automationsListForApiDocumentation,
  automationWithoutOutput,
} from '../../../helpers/constants';
import './styles.scss';

const Automation: FC<IPageContextAutomatin> = ({pageContext}) => {
  const result = {
    ...pageContext.result.automation,
    options: [
      {label: 'Option 1', value: 0},
      {label: 'Option 2', value: 1},
    ],
    onChange: () => {},
  };

  //const [showAPIDoc, setShowAPIDoc] = useState(true);

  // const automations = pageContext.result.automations.slice(0, 3);

  // const getToolAPI = (url: string) => {
  //   let Component = null;
  //   if (url === 'discover-tech-stack') {
  //     Component = <Discovertechstack />;
  //   } else if (url === 'discover-whois') {
  //     Component = <Discoverwhois />;
  //   }
  //   return Component ? (
  //     <div className="details-wrapper api-request">
  //       <div className={`header-collapse ${showAPIDoc ? 'collapsed' : ''}`} onClick={() => setShowAPIDoc(!showAPIDoc)}>
  //         <div className="workflows-title">{result.name} API</div>
  //         <div className="ml-auto">{showAPIDoc ? arrowDownIcon : arrowRight}</div>
  //       </div>
  //       {showAPIDoc ? Component : null}
  //     </div>
  //   ) : null;
  // };

  const getWorkflowPath = (name: string) => {
    const singleWorkflowPath = name.trim().toLowerCase();
    const cleanPath = singleWorkflowPath.replace(/ /g, '-');
    return `/ready-made-workflow-library/${cleanPath}`;
  };

  return (
    <>
      <Helmet title={result.meta_title} key={result.meta_title}>
        <script type="text/javascript">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WSDXCCK');`}
        </script>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="/android-chrome-192x192.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link
          data-react-helmet="true"
          rel="canonical"
          href={`https://hexomatic.com/automation/${result.url.toLocaleLowerCase()}`}
        />
        <meta name="msapplication-TileColor" content="#03b8ff" />
        <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="msapplication-config" content="/browserconfig.xml" />

        <meta name="description" content={result.meta_description} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={'https://hexomatic.com/Home-page-illustrations.jpg'} />
        <meta property="og:url" content={`https://hexomatic.com/automation/${result.url.toLocaleLowerCase()}`} />
        <meta property="og:title" content={result.meta_title} />
        <meta property="og:description" content={result.meta_description} />
        <meta property="og:locale" content={'en_US'} />
        <meta name="twitter:image" content={'https://hexomatic.com/Home-page-illustrations.jpg'} />
        <meta name="twitter:title" content={result.meta_title} />
        <meta name="twitter:description" content={result.meta_description} />
        <meta name="twitter:card" content={'summary_large_image'} />
        <meta name="twitter:site" content={'@hexactinc'} />
      </Helmet>

      <Layout>
        <PageHero title="" titleSmall minHeight id="seo_home_area_automations">
          <Breadcrumb
            items={[
              {
                to: `/automations`,
                name: 'Automations',
                active: false,
              },
              {
                to: `/automations/${cleanURL(result?.type?.name || '')}`,
                name: result?.type?.name,
                active: false,
              },
              {
                to: `/automations/${cleanURL(result?.category?.name || '')}`,
                name: result?.category?.name,
                active: false,
              },
              {
                to: `/automation/${cleanURL(result.url)}`,
                name: result?.name || '',
                active: true,
              },
            ]}
          />
        </PageHero>
        <Section>
          <div className="row automation-details mx-auto mt-0 mb-3 pl-2">
            <div className="col-12">
              <div className="automation-details-wrapper">
                <div className="details-wrapper">
                  <AutomationDetailsHeader props={{...result}} />
                  <AutomationDetailsContent description={result.long_description} />
                  {automationsListForApiDocumentation.includes(result.url) && (
                    <Link to={`/automation/${result.url}/api-documentation`} className="d-block mt-2">
                      {result.name} API documentation
                    </Link>
                  )}
                  {!automationWithoutOutput.find((id: number) => id === result.id) && (
                    <AutomationInputOutput
                      inputList={result.inputs ? result.inputs.split(',') : []}
                      outputList={
                        result.outputs && JSON.parse(result.outputs)
                          ? Object.keys(JSON.parse(result.outputs))
                          : //@ts-ignore
                          automationOutputs[result.id]
                          ? //@ts-ignore
                            automationOutputs[result.id]
                          : ['none']
                      }
                    />
                  )}
                </div>
                {result?.long_description_details?.trim() ? (
                  <div style={{marginBottom: '60px'}}>
                    <h6 style={{fontWeight: 700, fontSize: '18px'}}>How to use {result.name} automation</h6>
                    <AutomationDetailsContent description={result.long_description_details} />
                    <div className="text-center mt-3 mb-5">
                      <Button
                        cookie
                        className="cta-btn"
                        onClick={() => {
                          window.location.href = window.location.href.includes('http://37.186.119.181')
                            ? `http://37.186.119.181:3279/workflows/create/?automation-id=${props.id}${
                                !result.has_source ? '&' : ''
                              }${!result.has_source ? 'requries-data-input' : ''}`
                            : `https://dash.hexomatic.com/workflows/create/?automation-id=${result.id}${
                                !result.has_source && !automationWithoutDataInput.includes(result.id) ? '&' : ''
                              }${!result.has_source && !automationWithoutDataInput.includes(result.id) ? 'requries-data-input' : ''}`;
                        }}
                      >
                        Create workflow
                      </Button>
                    </div>
                  </div>
                ) : null}
                {result.containedPublicWorkflows && result.containedPublicWorkflows.length > 0 && (
                  <div style={{marginBottom: '60px'}}>
                    <div className="workflows-title">Workflows containing {result.name} automation</div>
                    <div className="row">
                      {result.containedPublicWorkflows
                        .filter((item: any, index: number) => index < 4)
                        .map((subItem: any) => (
                          <div className="col-12 col-lg-6 px-2">
                            <Link to={getWorkflowPath(subItem.public_workflow_name)}>
                              <WorkflowCard
                                details
                                data={{
                                  id: subItem.public_workflow_id,
                                  name: subItem.public_workflow_name,
                                  description: subItem.public_workflow_description,
                                  tags: subItem.tags || [],
                                  steps:
                                    subItem.steps && subItem.steps.length > 0
                                      ? subItem.steps.map((sub: any) => {
                                          return {
                                            automation: {name: sub.automation_name, icon_url: sub.automation_icon_url},
                                          };
                                        })
                                      : [],
                                }}
                                state="/ready-made-workflow-library"
                                fullWidth
                              />
                            </Link>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="col-12 col-md-5 col-lg-6 col-xl-4">
              <div className="recommend-title">
                <div>Other similar</div>
                <div className="see-all">
                  {!!automations.length ? (
                    <Link
                      to={`/automations/${cleanURL(result?.type?.name || '')}/${cleanURL(
                        result?.category?.name || '',
                      )}`}
                    >
                      Show all
                    </Link>
                  ) : (
                    <span>Show all</span>
                  )}
                </div>
              </div>
              <div className="recommend-automations">
                {!!automations.length ? (
                  automations.map(item => {
                    return (
                      <div className="recommend-automation">
                        <Link to={`/automation/${cleanURL(item.url)}`}>
                          <AutomationCards
                            title={item.name}
                            description={item.short_description}
                            icon={item.icon_url}
                            is_new={item.is_new}
                            rate={4.1}
                            active={true}
                            //@ts-ignore
                            credit_price={item.credit_price}
                            //@ts-ignore
                            premium_credit_price={item.premium_credit_price}
                            type={item.type}
                          />
                        </Link>
                      </div>
                    );
                  })
                ) : (
                  <NothingToShow />
                )}
              </div>
            </div> */}
          </div>
        </Section>
      </Layout>
    </>
  );
};

export default Automation;
