import React, { FC } from "react";
import TranslateMarkdown from "../../../components/translation-markdown";

import "./styles.scss";

const AutomationDetailsContent: FC<{ description: string }> = ({
  description,
}) => (
  <div className="automation-details-footer">
    <TranslateMarkdown text={description} />
  </div>
);

export default AutomationDetailsContent;
