import {Link, navigate} from 'gatsby';
import React, {FC} from 'react';
import { useMediaQuery } from 'react-responsive';
import {WorkflowType} from '../../../types';
import './styles.scss';

const AutomationCards: FC<{data: WorkflowType; state: string; fullWidth?: boolean, details?: boolean}> = ({data, state, fullWidth, details}) => {
  const namesForTooltip = data.steps
    .map((item: any, index: number) => index >= 8 && item.automation.name)
    .filter((item: any) => item);

  const getWorkflowPath = (name: string) => {
    const singleWorkflowPath = name.trim().toLowerCase();
    const cleanPath = singleWorkflowPath.replace(/ /g, '-');
    return `/ready-made-workflow-library/${cleanPath}`;
  };

  const isMobile = useMediaQuery({maxWidth: 1200});

  return (
    <div className={`workflow-card col-12 ${fullWidth ? 'px-0 py-2' : 'col-md-6 col-lg-4 py-2 px-2'} ${details ? 'h-100' : ''}`}>
      <Link to={getWorkflowPath(data.name)} state={{linkTo: state}} className="single-card-item">
        <div className="main-container">
          <div className="single-card-item-header">
            <h5>{data.name}</h5>
          </div>
          {data.description && data.description.trim() && (
            <div className="single-card-item-body">
              <h6>{data.description}</h6>
            </div>
          )}
        </div>

        <div className="single-card-item-footer">
          <div className="tags-container">
            {data.tags.map((item: any) => (
              <Link
                to={`/ready-made-workflow-library/${item.toLowerCase()}`}
                className={`category-tag ${item.toLowerCase()}`}
              >
                {item}
              </Link>
            ))}
          </div>
          <div className="icons-container">
            {data.steps.map(
              (item: any, index: number) =>
                index < (isMobile ? 8 : 3) && (
                  <div className="automation-card-icon overlaping" title={item.automation.name}>
                    <img src={item.automation.icon_url} alt={item.automation.name} />
                  </div>
                ),
            )}
            {data.steps.length > (isMobile ? 8 : 3) && (
              <div
                className="automation-card-icon overlaping"
                title={namesForTooltip.join(', ')}
                style={{paddingTop: '5px', fontSize: '12px', justifyContent: 'center'}}
              >
                + {data.steps.length - (isMobile ? 8 : 3)}
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default AutomationCards;
