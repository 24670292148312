import React, {FC, useState, useEffect} from 'react';
import {newLabelIcon} from '../../../images/svgIcons';
import {ITransformAutomationDetailsProps} from '../../../types';
import Button from '../../button';
import './styles.scss';

export const automationWithoutDataInput = [47, 46, 108, 125];

const AutomationDetailsHeader: FC<ITransformAutomationDetailsProps> = ({props}) => {
  const [creditPrice, setCreditPrice] = useState(0);
  const [premiumPrice, setPremiumPrice] = useState(0);
  useEffect(() => {
    let credit = 0,
      premium = 0;
    props &&
      props.prices &&
      props.prices.map((item: any) => {
        item && item.credit_price && (credit += item.credit_price);
        item && item.premium_credit_price && (premium += item.premium_credit_price);
        return;
      });
    setCreditPrice(credit);
    setPremiumPrice(premium);
  }, [props]);

  return (
    <div className="automation-details">
      <div className="automation-card-header main-card">
        <div className="automation-header-right">
          <div className="automation-card-icon">
            <img
              src={props.icon_url}
              alt="icon_url"
              style={{
                width: props.name.toLocaleLowerCase().includes('human') ? '45px' : '',
                maxHeight: props.name.toLocaleLowerCase().includes('human') ? '45px' : '',
              }}
            />
          </div>
          <div className="automation-card-header-text">
            <div className="automation-card-title">
              <h1>{props.name}</h1>
              <div className="d-md-flex">
                {props.is_new && <div className="automation-new-tag">{newLabelIcon}</div>}
                {props.id === 35 || (props.id === 36 && <div className="automation-beta-tag">BETA</div>)}
              </div>
            </div>
            <div className="d-none d-md-block">
              <div>{props.short_description}</div>
              <div>
                <small>
                  {props.type && props.type.name ? `${props.type.name} | ` : ''}
                  {creditPrice ? `Credits: ${creditPrice} ` : ''}
                  {creditPrice &&
                  premiumPrice &&
                  !(props.id === 6 || props.id === 7 || props.id === 81 || props.id === 97)
                    ? ' | '
                    : ''}
                  {premiumPrice && !(props.id === 6 || props.id === 7 || props.id === 81 || props.id === 97)
                    ? `Premium Credits: ${props.id === 125 ? 'Based on query volume' : premiumPrice}`
                    : ''}
                </small>
              </div>
            </div>
            {/*<div className="automation-rating-block">
            <span className="automation-star-icon">{starIcon}</span>`
            <span className="automation-progress-bar">
              <span className="automation-progress-bar-filled" style={{ width: `${((props.rate || 5) / 5) * 100}%` }}></span>
            </span>
            <span className="automation-rating">{props.rate}</span>
          </div>*/}
          </div>
        </div>
        <div className="crt-workflow-wrapper">
          <Button
            cookie
            onClick={() => {
              window.location.href = window.location.href.includes('http://37.186.119.181')
                ? `http://37.186.119.181:3279/workflows/create/?automation-id=${props.id}${
                    !props.has_source ? '&' : ''
                  }${!props.has_source ? 'requries-data-input' : ''}`
                : `https://dash.hexomatic.com/workflows/create/?automation-id=${props.id}${
                    !props.has_source && !automationWithoutDataInput.includes(props.id) ? '&' : ''
                  }${!props.has_source && !automationWithoutDataInput.includes(props.id) ? 'requries-data-input' : ''}`;
            }}
          >
            Create workflow
          </Button>
          {/* {tools.includes(props.id) && (
            <Button className=" mt-1" onClick={() => navigate(`/tools/${cleanURL(props.url)}`)}>
              Try it now for free!
            </Button>
          )} */}
        </div>
        {/* <div className="automation-header-left">
        <a href='https://dash.hexomatic.com/workflows/create' no-index><div className='automation-try'>Try this automation</div></a>
        <div className={`automation-tag-icon ${props.active ? '' : 'disabled'}`}>{tagIcon}</div>
        <div className="automation-menu-btn">
            {menuBtn}
            <Select showLeft icon={menuBtn} options={props.options} onChange={() => props.onChange && props.onChange(0)}/>
        </div>
      </div> */}
      </div>
      <div className="d-md-none mb-4">
        <div>{props.short_description}</div>
        <div>
          <small>
            {props.type && props.type.name ? `${props.type.name} | ` : ''}
            {creditPrice ? `Credits: ${creditPrice} ` : ''}
            {creditPrice && premiumPrice && !(props.id === 6 || props.id === 7 || props.id === 81 || props.id === 97)
              ? ' | '
              : ''}
            {premiumPrice && !(props.id === 6 || props.id === 7 || props.id === 81 || props.id === 97)
              ? `Premium Credits: ${props.id === 125 ? 'Based on query volume' : premiumPrice}`
              : ''}
          </small>
        </div>
      </div>
    </div>
  );
};

export default AutomationDetailsHeader;
