import React, {FC, memo} from 'react';
import {arrowRightIcon} from '../../../images/svgIcons';
import './styles.scss';

interface IProps {
  inputList: string[];
  outputList: string[];
}

const AutomationInputOutput: FC<IProps> = ({inputList, outputList}) => (
  <div className="automation-input-output-wrapper ">
    <div className="input-output input position-relative">
      <h6>Input</h6>
      <p>
        {inputList.map((el: string, index: number) => (
          <>
            <span className="d-inline-block">{el}</span>
            {index !== inputList.length - 1 && <span className="px-2">|</span>}
          </>
        ))}
      </p>
      <div className="position-absolute arrow-icon">{arrowRightIcon}</div>
    </div>

    <div className="input-output output">
      <h6>Output</h6>
      <p className="output-wrap">
        {outputList.map((el: string, index: number) => (
          <span className={`d-inline-block  ${index !== outputList.length - 1 ? 'pr-2' : ''}`}>
            {el.replace(/_/g, ' ').trim()} {index !== outputList.length - 1 && ','}
          </span>
        ))}
      </p>
    </div>
  </div>
);

export default memo(AutomationInputOutput);
